import React, { useEffect, useState } from "react";
import Header from "../../views/layout/Header";
import AdminSideBar from "../../views/layout/AdminSideBar";
import { axiosInstance } from "../../helpers/axios/axios";
import {
  LogisticsClientsURL,
  LogisticsCompaniesURL,
  LogisticsPOInward,
  LogisticsStorage,
  LogisticsWarehouse,
  exportInWardData,
  inwardFormUrl,
} from "../../helpers/endpoints/api_endpoints";
import { Link } from "react-router-dom";
import { Button, Dropdown, Form, Modal, Table } from "react-bootstrap";
import { Select } from "antd";
import { toast } from "react-toastify";
import { Loader } from "react-feather";
import ExportModal from "./ExportModal";

function ViewDetailsModal({
  show,
  handleClose,
  updateOutwardInList,
  removeOutwardFromList,
}) {
  const [formData, setFormData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [productList, setProductList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [storageData, setStorageData] = useState([]);
  const [warehouseData, setWarehouseData] = useState([]);
  const [storageList, setStorageList] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);

  useEffect(() => {
    if (show) {
      axiosInstance.get(`${inwardFormUrl}${show}/`).then(
        (response) => {
          console.log(response.data);
          setFormData(response.data);
          setOriginalData(response.data); // Save original data
        },
        (error) => {
          console.log(error);
        }
      );

      // Fetch products data
      axiosInstance.get(LogisticsCompaniesURL).then(
        (response) => {
          setProductList(response.data);
        },
        (error) => {
          console.log(error);
        }
      );

      // Fetch client data
      axiosInstance.get(LogisticsClientsURL).then(
        (response) => {
          setClientList(response.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      setIsEditing(false);
      setFormData(null);
      setOriginalData(null); // Reset original data
    }
  }, [show]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSelectChange = (name, value, label) => {
    const selectedValue =
      name === "client"
        ? value
        : label && name === "company"
        ? value
        : label && name === "storage_location"
        ? value
        : label && name === "warehouse"
        ? value
        : label;
    console.log(selectedValue);
    setFormData({ ...formData, [name]: selectedValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const changedData = {};
    for (let key in formData) {
      if (formData[key] !== originalData[key]) {
        changedData[key] = formData[key];
      }
    }

    console.log("Changed Data: ", changedData);

    axiosInstance.patch(`${inwardFormUrl}${show}/`, changedData).then(
      (response) => {
        console.log(response.data);
        updateOutwardInList(response.data);
        setIsEditing(false);
        handleClose();
        toast.success("Saved successfully");
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleDelete = () => {
    axiosInstance.delete(`${inwardFormUrl}${show}/`).then(
      () => {
        removeOutwardFromList(show);
        setShowDeleteConfirmation(false);
        handleClose();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const openDeleteConfirmation = () => {
    setShowDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  const ClientList = clientList?.results?.map((company) => ({
    value: company.id,
    label: company?.name,
  }));

  const CompanyList = productList?.results?.map((company) => ({
    value: company.id,
    label: company?.name,
  }));
  const StorageLocation = storageList?.results?.map((company) => ({
    value: company.id,
    label: company.storage_location,
  }));
  const Warehouse = warehouseList?.results?.map((company) => ({
    value: company.id,
    label: company.location,
  }));
  const transportType = [
    { value: "1", label: "Air" },
    { value: "2", label: "Shipping" },
    { value: "3", label: "Surface" },
  ];
  const typeDropdownData = [
    { value: "1", label: "ATM Movement" },
    { value: "2", label: "Household" },
    { value: "3", label: "3PL" },
    { value: "4", label: "Other" },
  ];
  const ATMCategory = [
    { value: "1", label: "Primary" },
    { value: "2", label: "Secondary" },
  ];
  const ATMType = [
    { value: "1", label: "Pickup" },
    { value: "2", label: "Buyback" },
    { value: "3", label: "Shifting" },
    { value: "4", label: "Others" },
  ];
  const stateNames = [
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" },
  ];
  // fetch storage location data
  useEffect(() => {
    axiosInstance.get(`${LogisticsStorage}${formData?.storage_location}/`).then(
      (response) => {
        console.log(response.data);
        setStorageData(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [formData?.storage_location]);
  // fetch warehouse data
  useEffect(() => {
    axiosInstance.get(`${LogisticsWarehouse}${formData?.warehouse}/`).then(
      (response) => {
        console.log(response.data);
        setWarehouseData(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [formData?.warehouse]);
  // fetch storage location data
  useEffect(() => {
    axiosInstance
      .get(`${LogisticsStorage}?warehouse=${formData?.warehouse}`)
      .then(
        (response) => {
          console.log(response.data);
          setStorageList(response.data);
        },
        (error) => {
          console.log(error);
        }
      );
  }, [formData?.warehouse]);
  // fetch Warehouse data
  useEffect(() => {
    axiosInstance.get(`${LogisticsWarehouse}`).then(
      (response) => {
        console.log(response.data);
        setWarehouseList(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  return (
    <>
      <Modal
        centered
        size="lg"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Inward Form Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formData ? (
            <>
              {isEditing ? (
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formType">
                    <div className="input-block mb-3 row align-items-center">
                      <label className="col-lg-3 col-form-label mb-0 mt-2">
                        State
                      </label>
                      <div className="col-lg-8">
                        <Select
                          className="form-control"
                          options={stateNames}
                          placeholder="Select State"
                          onChange={(value, option) =>
                            handleSelectChange("state", value, option.label)
                          }
                          required
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="formType">
                    <div className="input-block mb-3 row align-items-center">
                      <label className="col-lg-3 col-form-label mb-0 mt-2">
                        Type
                      </label>
                      <div className="col-lg-8">
                        <Select
                          className="form-control"
                          options={typeDropdownData}
                          placeholder="Select Type"
                          onChange={(value, option) =>
                            handleSelectChange("type", value, option.label)
                          }
                          required
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="formClient">
                    <div className="input-block mb-3 row align-items-center">
                      <label className="col-lg-3 col-form-label mb-0 mt-2">
                        Client
                      </label>
                      <div className="col-lg-8">
                        <Select
                          className="form-control"
                          options={ClientList}
                          placeholder="Select Client Name"
                          onChange={(value, option) =>
                            handleSelectChange("client", value, option.label)
                          }
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="formCompany">
                    <div className="input-block mb-3 row align-items-center">
                      <label className="col-lg-3 col-form-label mb-0 mt-2">
                        Company Name
                      </label>
                      <div className="col-lg-8">
                        <Select
                          className="form-control"
                          options={CompanyList}
                          placeholder="Select Company Name"
                          onChange={(value, option) =>
                            handleSelectChange("company", value, option.label)
                          }
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="formATMCategory">
                    {formData?.type === "ATM Movement" && (
                      <div className="input-block mb-3 row align-items-center">
                        <label className="col-lg-3 col-form-label mb-0 mt-2">
                          ATM Category
                        </label>
                        <div className="col-lg-8">
                          <Select
                            className="form-control"
                            options={ATMCategory}
                            placeholder="Select ATM Category"
                            onChange={(value, option) =>
                              handleSelectChange(
                                "atm_category",
                                value,
                                option.label
                              )
                            }
                          />
                        </div>
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group controlId="formATMType">
                    {formData?.type === "ATM Movement" &&
                      formData?.atm_category === "Secondary" && (
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label mb-0 mt-2">
                            ATM Type
                          </label>
                          <div className="col-lg-8">
                            <Select
                              className="form-control"
                              options={ATMType}
                              placeholder="Select ATM Type"
                              onChange={(value, option) =>
                                handleSelectChange(
                                  "secondary_type",
                                  value,
                                  option.label
                                )
                              }
                            />
                          </div>
                        </div>
                      )}
                  </Form.Group>
                  <Form.Group controlId="formTransportType">
                    <div className="input-block mb-1 mt-2 row align-items-center">
                      <label className="col-lg-3 col-form-label mb-0 mt-2">
                        Transport Type
                      </label>
                      <div className="col-lg-8">
                        <Select
                          className="form-control"
                          options={transportType}
                          placeholder="Select Transport Type"
                          onChange={(value, option) =>
                            handleSelectChange(
                              "transport_type",
                              value,
                              option.label
                            )
                          }
                        />
                      </div>
                    </div>
                  </Form.Group>

                  <Form.Group controlId="formTransportType">
                    <div className="input-block mb-1 mt-2 row align-items-center">
                      <label className="col-lg-3 col-form-label mb-0 mt-2">
                        Warehouse
                      </label>
                      <div className="col-lg-8">
                        <Select
                          className="form-control"
                          options={Warehouse}
                          placeholder="Select Warehouse"
                          onChange={(value, option) =>
                            handleSelectChange("warehouse", value, option.label)
                          }
                        />
                      </div>
                    </div>
                  </Form.Group>
                  {formData?.warehouse && (
                    <Form.Group controlId="formTransportType">
                      <div className="input-block mb-1 mt-2 row align-items-center">
                        <label className="col-lg-3 col-form-label mb-0 mt-2">
                          Storage Location
                        </label>
                        <div className="col-lg-8">
                          <Select
                            className="form-control"
                            options={StorageLocation}
                            placeholder="Select Storage Location"
                            onChange={(value, option) =>
                              handleSelectChange(
                                "storage_location",
                                value,
                                option.label
                              )
                            }
                          />
                        </div>
                      </div>
                    </Form.Group>
                  )}

                  {formData?.type === "ATM Movement" && (
                    <Form.Group controlId="formATMID">
                      <Form.Label className="mb-0 mt-2">ATM ID</Form.Label>
                      <Form.Control
                        type="text"
                        name="atm_id"
                        value={formData.atm_id}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  )}
                  {formData?.type === "ATM Movement" && (
                    <Form.Group controlId="formATMSerial">
                      <Form.Label className="mb-0 mt-2">ATM Serial</Form.Label>
                      <Form.Control
                        type="text"
                        name="atm_sno"
                        value={formData.atm_sno}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  )}
                  {formData?.type === "ATM Movement" && (
                    <Form.Group controlId="formATMSerial">
                      <Form.Label className="mb-0 mt-2">From ATM ID</Form.Label>
                      <Form.Control
                        type="text"
                        name="from_atm"
                        value={formData.from_atm}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  )}
                  {formData?.type === "ATM Movement" && (
                    <Form.Group controlId="formATMSerial">
                      <Form.Label className="mb-0 mt-2">To ATM ID</Form.Label>
                      <Form.Control
                        type="text"
                        name="to_atm"
                        value={formData.to_atm}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  )}
                  <Form.Group controlId="formDocketNumber">
                    <Form.Label className="mb-0 mt-2">Docket Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="client_docket_number"
                      value={formData.client_docket_number}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formInvoiceNumber">
                    <Form.Label className="mb-0 mt-2">
                      Invoice Number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="client_invoice_number"
                      value={formData.client_invoice_number}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formInvoiceDate">
                    <Form.Label className="mb-0 mt-2">Invoice Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="client_invoice_date"
                      value={formData.client_invoice_date}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formInvoiceValue">
                    <Form.Label className="mb-0 mt-2">Invoice Value</Form.Label>
                    <Form.Control
                      type="text"
                      name="client_invoice_value"
                      value={formData.client_invoice_value}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formEwayBill">
                    <Form.Label className="mb-0 mt-2">E-Way Bill</Form.Label>
                    <Form.Control
                      type="text"
                      name="eway_bill"
                      value={formData.eway_bill}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formFromAddress">
                    <Form.Label className="mb-0 mt-2">From Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="from_name"
                      value={formData.from_name}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formFromAddress">
                    <Form.Label className="mb-0 mt-2">From Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="from_address"
                      value={formData.from_address}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formFromPincode">
                    <Form.Label className="mb-0 mt-2">From Pincode</Form.Label>
                    <Form.Control
                      type="text"
                      name="from_pincode"
                      value={formData.from_pincode}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formFromContact">
                    <Form.Label className="mb-0 mt-2">From Contact</Form.Label>
                    <Form.Control
                      type="text"
                      name="from_contact"
                      value={formData.from_contact}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formFromEmail">
                    <Form.Label className="mb-0 mt-2">From Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="from_email"
                      value={formData.from_email}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formToAddress">
                    <Form.Label className="mb-0 mt-2">To Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="to_name"
                      value={formData.to_name}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formToAddress">
                    <Form.Label className="mb-0 mt-2">To Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="to_address"
                      value={formData.to_address}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formToPincode">
                    <Form.Label className="mb-0 mt-2">To Pincode</Form.Label>
                    <Form.Control
                      type="text"
                      name="to_pincode"
                      value={formData.to_pincode}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formToContact">
                    <Form.Label className="mb-0 mt-2">To Contact</Form.Label>
                    <Form.Control
                      type="text"
                      name="to_contact"
                      value={formData.to_contact}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formToEmail">
                    <Form.Label className="mb-0 mt-2">To Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="to_email"
                      value={formData.to_email}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formReceiver">
                    <Form.Label className="mb-0 mt-2">Receiver</Form.Label>
                    <Form.Control
                      type="text"
                      name="receiver"
                      value={formData.receiver}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formSecondaryType">
                    <Form.Label className="mb-0 mt-2">
                      Secondary Type
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="secondary_type"
                      value={formData.secondary_type}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formSubclientName">
                    <Form.Label className="mb-0 mt-2">
                      Subclient Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="subclient_name"
                      value={formData.subclient_name}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formRemarks">
                    <Form.Label className="mb-0 mt-2">Remarks</Form.Label>
                    <Form.Control
                      type="text"
                      name="remarks"
                      value={formData.remarks}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  {/* {formData.items && formData.items.length > 0 && (
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Weight</th>
                          <th>Pieces</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formData.items.map((product) => (
                          <tr key={product.id}>
                            <td>{product?.name?.name || "N/A"}</td>
                            <td>{product.weight || "N/A"}</td>
                            <td>{product.pieces || "N/A"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )} */}

                  {/* <Button className="mt-3" variant="primary" type="submit">
                    Save Changes
                  </Button> */}
                </Form>
              ) : (
                <Table striped bordered hover>
                  <tbody>
                    <tr>
                      <th>Created Date</th>
                      <td>
                        {formData.created_at
                          ? new Date(formData.created_at).toLocaleString()
                          : "NA"}
                      </td>
                    </tr>
                    <tr>
                      <th>State</th>
                      <td>{formData.state ? formData.state : "NA"}</td>
                    </tr>
                    <tr>
                      <th>Type</th>
                      <td>{formData.type ? formData.type : "NA"}</td>
                    </tr>
                    <tr>
                      <th>Client</th>
                      <td>
                        {formData.client_details?.name
                          ? formData.client_details?.name
                          : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <th>Company</th>
                      <td>
                        {formData.company_details?.name
                          ? formData.company_details?.name
                          : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <th>Warehouse </th>
                      <td>
                        {warehouseData?.location
                          ? warehouseData?.location
                          : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <th>Storage Location</th>
                      <td>
                        {storageData?.storage_location
                          ? storageData?.storage_location
                          : "N/A"}
                      </td>
                    </tr>

                    <tr>
                      <th>Docket Number</th>
                      <td>
                        {formData.client_docket_number
                          ? formData.client_docket_number
                          : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <th> VT Docket Number</th>
                      <td>
                        {formData.vt_docket_number
                          ? formData.vt_docket_number
                          : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <th>Invoice Number</th>
                      <td>
                        {formData.client_invoice_number
                          ? formData.client_invoice_number
                          : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <th>Invoice Date</th>
                      <td>
                        {formData.client_invoice_date
                          ? formData.client_invoice_date
                          : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <th>Invoice Value</th>
                      <td>
                        {formData.client_invoice_value
                          ? formData.client_invoice_value
                          : "NA"}
                      </td>
                    </tr>
                    {formData?.type === "ATM Movement" && (
                      <tr>
                        <th>ATM Category</th>
                        <td>
                          {formData.atm_category ? formData.atm_category : "NA"}
                        </td>
                      </tr>
                    )}

                    {formData?.type === "ATM Movement" &&
                      formData?.atm_category === "Secondary" && (
                        <tr>
                          <th>Secondary Type</th>
                          <td>
                            {formData.secondary_type
                              ? formData.secondary_type
                              : "NA"}
                          </td>
                        </tr>
                      )}

                    {formData?.type === "ATM Movement" && (
                      <tr>
                        <th>ATM ID</th>
                        <td>{formData.atm_id ? formData.atm_id : "NA"}</td>
                      </tr>
                    )}
                    {formData?.type === "ATM Movement" && (
                      <tr>
                        <th>ATM S.No</th>
                        <td>{formData.atm_sno ? formData.atm_sno : "NA"}</td>
                      </tr>
                    )}
                    {formData?.type === "ATM Movement" && (
                      <tr>
                        <th>To ATM ID</th>
                        <td>{formData.to_atm ? formData.to_atm : "NA"}</td>
                      </tr>
                    )}
                    {formData?.type === "ATM Movement" && (
                      <tr>
                        <th>From ATM ID</th>
                        <td>{formData.from_atm ? formData.from_atm : "NA"}</td>
                      </tr>
                    )}
                    {formData?.type === "ATM Movement" && (
                      <tr>
                        <th>E-Way Bill</th>
                        <td>
                          {formData.eway_bill ? formData.eway_bill : "NA"}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th>From Name</th>
                      <td>{formData.from_name ? formData.from_name : "NA"}</td>
                    </tr>
                    <tr>
                      <th>From Address</th>
                      <td>
                        {formData.from_address ? formData.from_address : "NA"}
                      </td>
                    </tr>
                    <tr>
                      <th>From Pincode</th>
                      <td>
                        {formData.from_pincode ? formData.from_pincode : "NA"}
                      </td>
                    </tr>
                    <tr>
                      <th>From Contact</th>
                      <td>
                        {formData.from_contact ? formData.from_contact : "NA"}
                      </td>
                    </tr>
                    <tr>
                      <th>From Email</th>
                      <td>
                        {formData.from_email ? formData.from_email : "NA"}
                      </td>
                    </tr>
                    <tr>
                      <th>To Name</th>
                      <td>{formData.to_name ? formData.to_name : "NA"}</td>
                    </tr>
                    <tr>
                      <th>To Address</th>
                      <td>
                        {formData.to_address ? formData.to_address : "NA"}
                      </td>
                    </tr>
                    <tr>
                      <th>To Pincode</th>
                      <td>
                        {formData.to_pincode ? formData.to_pincode : "NA"}
                      </td>
                    </tr>
                    <tr>
                      <th>To Contact</th>
                      <td>
                        {formData.to_contact ? formData.to_contact : "NA"}
                      </td>
                    </tr>
                    <tr>
                      <th>To Email</th>
                      <td>{formData.to_email ? formData.to_email : "NA"}</td>
                    </tr>
                    <tr>
                      <th>Receiver</th>
                      <td>{formData.receiver ? formData.receiver : "NA"}</td>
                    </tr>

                    <tr>
                      <th>Subclient Name</th>
                      <td>
                        {formData.subclient_name
                          ? formData.subclient_name
                          : "NA"}
                      </td>
                    </tr>
                    <tr>
                      <th>Transport Type</th>
                      <td>
                        {formData.transport_type
                          ? formData.transport_type
                          : "NA"}
                      </td>
                    </tr>

                    <tr>
                      <th>Remarks</th>
                      <td>{formData.remarks ? formData.remarks : "NA"}</td>
                    </tr>
                    <tr>
                      <th>Products</th>
                      <td>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Pieces</th>
                              <th>Weight</th>
                            </tr>
                          </thead>
                          <tbody>
                            {formData.items?.map((product, index) => (
                              <tr key={index}>
                                <td>{product?.name?.name}</td>
                                <td>{product.number}</td>
                                <td>{product.weight}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}
              <div className="d-flex justify-content-end mt-2">
                <Button
                  variant="secondary"
                  onClick={handleClose}
                  className="me-2"
                >
                  Close
                </Button>
                {formData.is_completed === false && isEditing ? (
                  <Button variant="success" onClick={handleSubmit}>
                    Save
                  </Button>
                ) : (
                  formData.is_completed === false && (
                    <Button variant="primary" onClick={handleEditToggle}>
                      Edit
                    </Button>
                  )
                )}
                {/* {formData.is_completed === false && (
                  <Button
                    variant="danger"
                    onClick={openDeleteConfirmation}
                    className="ms-2"
                  >
                    Delete
                  </Button>
                )} */}
              </div>
            </>
          ) : (
            <p>
              {" "}
              loading...{" "}
              <Loader
                className="feather-loader"
                data-bs-toggle="tooltip"
                title="feather-loader"
              />
            </p>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeleteConfirmation}
        onHide={closeDeleteConfirmation}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this outward form?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteConfirmation}>
            Cancel
          </Button>
          {/* <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

const InwardList = () => {
  const [clientDocket, setClientDocket] = useState();
  const [VTDocket, setVTDocket] = useState();
  const [inwardList, setInwardList] = useState([]);
  const [show, setShow] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [clients, setClients] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedClient, setSelectedClient] = useState({
    id: "",
    name: "Select Client",
  });
  const [selectedCompany, setSelectedCompany] = useState({
    id: "",
    name: "Select Company",
  });
  const [selectedWarehouse, setSelectedWarehouse] = useState({
    id: "",
    name: "Select warehouse",
  });
  console.log(selectedWarehouse);
  const [state, setState] = useState(""); // State for type filter
  const [type, setType] = useState(""); // State for type filter
  const [createdAt, setCreatedAt] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [approvals, setApprovals] = useState("");
  const [atmId, setAtmId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [warehouse, setWarehouse] = useState([]);
  console.log(warehouse);
  const handleClose = () => setShow(false);
  const handleShow = (id) => setShow(id);

  useEffect(() => {
    fetchClients();
    fetchCompanies();
    fetchInwardList();
  }, []);
  // fetch warehouse data
  useEffect(() => {
    axiosInstance.get(`${LogisticsWarehouse}`).then(
      (response) => {
        console.log(response.data);
        setWarehouse(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);
  const fetchClients = async () => {
    try {
      const response = await axiosInstance.get(LogisticsClientsURL);
      setClients(response.data);
    } catch (error) {
      console.error("Error fetching clients: ", error);
    }
  };

  const fetchCompanies = async () => {
    try {
      const response = await axiosInstance.get(LogisticsCompaniesURL);
      setCompanies(response.data);
    } catch (error) {
      console.error("Error fetching companies: ", error);
    }
  };

  const fetchInwardList = async (filters = {}, page = 1) => {
    setIsLoading(true);
    console.log(filters);
    try {
      const response = await axiosInstance.get(inwardFormUrl, {
        params: { ...filters, page },
      });

      console.log(response.data);
      setInwardList(response.data);
      setTotalPages(Math.ceil(response.data.count / 20)); // Assuming 20 items per page
    } catch (error) {
      console.error("Error fetching inward list: ", error);
    }
    setIsLoading(false);
  };
  console.log(type);

  const handleFilterChange = () => {
    const filters = {
      warehouse: selectedWarehouse.id,
      client: selectedClient.id,
      company: selectedCompany.id,
      start_date: startDate,
      created_at: createdAt,
      end_date: endDate,
      is_completed: approvals,
      atm_id: atmId,
      type: type,
      client_docket_number: clientDocket,
      vt_docket_number: VTDocket,
      state: state,
    };
    fetchInwardList(filters, currentPage);
  };
  useEffect(() => {
    handlePageChange(currentPage);
  }, [
    selectedClient.id,
    selectedCompany.id,
    startDate,
    createdAt,
    endDate,
    atmId,
    approvals,
    clientDocket,
    type,
    state,
    selectedWarehouse.id,
    VTDocket,
  ]);
  const handleResetFilters = () => {
    setSelectedClient({ id: "", name: "Select Client" });
    setSelectedCompany({ id: "", name: "Select Company" });
    setStartDate("");
    setCreatedAt("");
    setEndDate("");
    setApprovals("");
    setAtmId("");
    fetchInwardList({}, 1);
    setType(""); // Reset type filter
    setClientDocket("");
    setVTDocket("");
    setState("");
    setSelectedWarehouse({ id: "", name: "Select warehouse " });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    handleFilterChange(newPage);
  };

  const handleExport = async (filters = {}) => {
    try {
      const response = await axiosInstance.get(exportInWardData, {
        params: {
          type: filters.type,
          company_name: filters.selectedCompany,
          client: filters.selectedClient,
          start_date: filters.startDate,
          end_date: filters.endDate,
        },
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "inward_data.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error exporting data: ", error);
    }
  };

  const updateOutwardInList = (updatedOutward) => {
    setInwardList((prevList) => {
      return {
        ...prevList,
        results: prevList?.results?.map((outward) =>
          outward.id === updatedOutward.id ? updatedOutward : outward
        ),
      };
    });
  };
  const stateNames = [
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" },
  ];
  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h2 className="text-start">Inward List</h2>
            <div>
              <Link
                to="/outward3pl-form"
                variant="primary"
                className="btn btn-primary btn-sm p-2 me-2"
              >
                Add 3PL outward
              </Link>
              <Button onClick={() => setShowExportModal(true)}>
                Export to Excel
              </Button>
            </div>
          </div>

          <div className="d-flex mb-2">
            <Dropdown
              onSelect={(e) =>
                setSelectedClient({
                  id: e,
                  name:
                    clients?.results?.find(
                      (client) => client.id === parseInt(e)
                    )?.name || "Select Client",
                })
              }
            >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {selectedClient.name}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {clients?.results?.map((client) => (
                  <Dropdown.Item key={client.id} eventKey={client.id}>
                    {client.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown
              className="mx-2"
              onSelect={(e) =>
                setSelectedCompany({
                  id: e,
                  name:
                    companies?.results?.find(
                      (company) => company.id === parseInt(e)
                    )?.name || "Select Company",
                })
              }
            >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {selectedCompany.name}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {companies?.results?.map((company) => (
                  <Dropdown.Item key={company.id} eventKey={company.id}>
                    {company.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="me-2" onSelect={(e) => setState(e)}>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {state ? state : "Select State"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {stateNames.map(({ value, label }) => (
                  <Dropdown.Item key={value} eventKey={value}>
                    {label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="me-2" onSelect={(e) => setType(e)}>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {type ? type : "Select Type"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="ATM Movement">
                  ATM Movement
                </Dropdown.Item>
                <Dropdown.Item eventKey="Household">Household</Dropdown.Item>
                <Dropdown.Item eventKey="3PL">3PL</Dropdown.Item>
                <Dropdown.Item eventKey="Other">Other</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown onSelect={(e) => setApprovals(e)}>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {approvals
                  ? approvals === "true"
                    ? "Completed Outwards"
                    : "Pending Outwards"
                  : "Select Status"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="true">
                  Completed Outwards
                </Dropdown.Item>
                <Dropdown.Item eventKey="false">Pending Outwards</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown
              className="mx-2"
              onSelect={(e) =>
                setSelectedWarehouse({
                  id: e,
                  name:
                    warehouse?.results?.find(
                      (company) => company.id === parseInt(e)
                    )?.location || "Select warehouse",
                })
              }
            >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {selectedWarehouse?.name}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {warehouse?.results?.map((company) => (
                  <Dropdown.Item key={company.id} eventKey={company.id}>
                    {company.location}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Form.Control
              className="mx-2"
              type="text"
              value={VTDocket}
              onChange={(e) => setVTDocket(e.target.value)}
              placeholder="VT Docket Number"
            />
          </div>

          <div className="text-end my-2 d-flex align-items-center justify-end">
            <Form.Control
              className="mx-2"
              type="text"
              value={clientDocket}
              onChange={(e) => setClientDocket(e.target.value)}
              placeholder="Client Docket Number"
            />
            <Form.Control
              type="text"
              value={atmId}
              onChange={(e) => setAtmId(e.target.value)}
              placeholder="ATM ID"
            />
            <Form.Control
              className="ms-2"
              type="text"
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              placeholder="Start Date"
            />

            <Form.Control
              className="mx-2"
              type="text"
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              placeholder="End Date"
            />

            <Form.Control
              className="mx-2"
              type="text"
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
              value={createdAt}
              onChange={(e) => setCreatedAt(e.target.value)}
              placeholder="Date"
            />
            <Button
              className="me-2 w-75"
              variant="primary"
              onClick={handleFilterChange}
            >
              Apply Filters
            </Button>
            <Button
              className="me-2 w-75"
              variant="secondary"
              onClick={handleResetFilters}
            >
              Reset Filters
            </Button>
          </div>

          <table className="table table-striped text-center">
            <thead className="table-secondary">
              <tr>
                <th>No.</th>
                <th>Client Name</th>
                <th>Type</th>
                <th>Client Docket Number</th>
                <th>VT Docket Number</th>
                <th>ATM ID</th>
                <th>Date</th>

                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {!isLoading ? (
                inwardList?.count > 0 ? (
                  inwardList?.results?.map((item, index) => (
                    <tr key={item.id}>
                      <td>{(currentPage - 1) * 20 + index + 1}</td>
                      <td>
                        {item?.client_details?.name
                          ? item?.client_details?.name
                          : "Not available"}
                      </td>
                      <td>{item?.type ? item.type : "Not available"}</td>
                      <td>
                        {item?.client_docket_number
                          ? item.client_docket_number
                          : "Not Available"}
                      </td>
                      <td>
                        {item?.vt_docket_number
                          ? item?.vt_docket_number
                          : "Not Available"}
                      </td>
                      <td>{item?.atm_id ? item?.atm_id : "Not Available"}</td>
                      <td>
                        {item?.updated_at
                          ? item?.updated_at?.slice(0, 10)
                          : "Not Available"}
                      </td>
                      <td>
                        {item.is_completed === true ? (
                          <div
                            variant="success"
                            className="btn btn-success btn-sm ms-1"
                          >
                            Outward done
                          </div>
                        ) : (
                          <Link
                            to={`/outward-form/${item.id}/${item.company_details?.id}`}
                            variant="primary"
                            className="btn btn-primary btn-sm ms-1"
                          >
                            Add Outward
                          </Link>
                        )}
                        <Button
                          className="btn btn-primary btn-sm ms-1"
                          variant="primary"
                          onClick={() => handleShow(item.id)}
                        >
                          View Details
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">No data</td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan="8">
                    {" "}
                    loading...{" "}
                    <Loader
                      className="feather-loader"
                      data-bs-toggle="tooltip"
                      title="feather-loader"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="d-flex justify-content-between">
            <Button
              variant="secondary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              variant="secondary"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>
          <ViewDetailsModal
            updateOutwardInList={updateOutwardInList}
            show={show}
            handleClose={handleClose}
          />
          <ExportModal
            show={showExportModal}
            handleClose={() => setShowExportModal(false)}
            handleExport={handleExport}
          />
        </div>
      </div>
    </>
  );
};

export default InwardList;
